
import Vue from 'vue';
import { eModeType } from '@/enums';
import { defaultThemes } from '@/constants';
import SitchThemeSelector from '@/components/custom-ui-components/SitchThemeSelector.vue';
import { deleteField, DocumentData, DocumentReference } from 'firebase/firestore';
import { updateMode } from '@/util-functions/firestore-mode-utils';
import { sitchClientUrl } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getModeIcon } from '@/util-functions/misc-utils';
import { showConfirmation, showSuccess, showNotice } from '@/util-functions/notice-utils';
import { updateUserModeGatewayDoc } from '@/util-functions/user-utils';
import { deleteImages } from '@/util-functions/image-utils';

export default Vue.extend({
  components: {
    SitchThemeSelector,
  },
  data(): {
    selectedModes: string[];
    currSelectedThemeId: string;
    uneditableThemeIds: string[];
  } {
    return {
      selectedModes: [],
      currSelectedThemeId: '',
      uneditableThemeIds: Object.values(defaultThemes).map((t) => t.id),
    };
  },
  computed: {
    modes(): ModesMap {
      return this.$store.state.modes;
    },
    selectedDefaultTheme(): boolean {
      return this.uneditableThemeIds.includes(this.currSelectedThemeId);
    },
    themes(): AestheticTheme[] {
      return this.$store.getters.themes;
    },
  },
  methods: {
    onPreviewClick(currMode: AnyMode) {
      const modeLink = currMode.linkId ? `${sitchClientUrl}/s/${currMode.linkId}` : `${sitchClientUrl}?u=${this.$store.state.userId}&am=${currMode.docId}`;
      window.open(modeLink, '_blank');
    },
    onNewTheme() {
      this.$router.push({ path: '/ThemeForm' });
    },
    onEditTheme() {
      this.$router.push({ path: `/ThemeForm?id=${this.currSelectedThemeId}` });
    },
    onDeleteTheme() {
      showConfirmation(t.confirmDeleteGeneric.supplant(['theme']), () => {
        const currTheme = this.$store.state.currUserModeGateway.themes[this.currSelectedThemeId] as AestheticTheme;

        deleteImages([...(currTheme.images || []), ...(currTheme.secondaryImages || [])])
          .then(() => {
            const gatewayFirestoreUpdate = {
              [`themes.${this.currSelectedThemeId}`]: deleteField(),
            };

            const updatedThemesObject = { ...this.$store.state.currUserModeGateway.themes };

            delete updatedThemesObject[this.currSelectedThemeId];

            const gatewayLocalStoreUpdate = {
              themes: updatedThemesObject,
            };

            updateUserModeGatewayDoc(gatewayFirestoreUpdate, gatewayLocalStoreUpdate).then(() => {
              showSuccess(t.genericDeleteSuccessful);
            });
          })
          .catch((error: any) => {
            showError(`Could not delete the images associated with this theme.`, error, true);
          });
      });
    },
    onModeSelectToggle(modeDocId: string) {
      const isSelected = this.selectedModes.includes(modeDocId);
      if (isSelected) {
        this.selectedModes = this.selectedModes.filter((modeId) => modeId !== modeDocId);
        this.$forceUpdate();
      } else {
        this.selectedModes.push(modeDocId);
      }
    },
    getModeIcon(type: eModeType): string {
      return getModeIcon(type);
    },
    onSelectAll() {
      this.selectedModes = Object.keys(this.modes);
    },
    onDeselectAll() {
      this.selectedModes = [];
    },
    onAssignTheme() {
      const allPromises: Promise<DocumentReference<DocumentData>>[] = [];
      this.selectedModes.forEach((modeId) => {
        allPromises.push(
          updateMode(
            modeId,
            {
              themeId: this.currSelectedThemeId,
            },
            true
          )
        );
      });

      Promise.all(allPromises)
        .then(() => {
          showNotice(t.modesUpdatedSuccessfully);
        })
        .catch((error: any) => {
          showError(`Could not assign themes to the selected Sitches.`, error, true);
        });
    },
    getTheme(themeId: string): AestheticTheme {
      return this.themes.find((theme) => theme.id === themeId) || defaultThemes.sitchLight;
    },
    getThemePreview(themeId: string) {
      const theme = this.getTheme(themeId);
      return { color: theme.accentColor, borderColor: theme.accentColor, background: theme.backgroundColor };
    },
  },
});
